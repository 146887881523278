'use client';

import { useState } from 'react';
import { useSearchParams } from 'next/navigation';
import updateQueryParam from '../../utils/updateQueryParam';
import useDidUpdate from '../lifecycle/useDidUpdate';
import useSearchStore from '../useSearchStore';

const useQueryParam = (name: string) => {
  const router = useSearchParams();
  const { setSeacrh } = useSearchStore();
  const query = router?.get(name)?.toString() ?? '';
  const [localQuery, setQuery] = useState(query);
  useDidUpdate(() => setQuery(query), [query]);
  useDidUpdate(() => {
    setSeacrh(localQuery);
  }, [localQuery, query]);
  useDidUpdate(() => updateQueryParam(name, localQuery), [name, localQuery]);

  const encodedQuery = encodeURI(localQuery);
  return [decodeURI(encodedQuery), setQuery] as const;
};

export default useQueryParam;
