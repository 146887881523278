import { useContext } from 'react';
import NavBarSearchContext from './NavBarSearchContext';

const useNavBarSearchContext = () => {
  const context = useContext(NavBarSearchContext);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (context === undefined) {
    throw new Error(
      `useNavBarSearchContext must be used within NavBarSearchProvider`,
    );
  }
  return context;
};

export default useNavBarSearchContext;
