'use client';
import { ChangeEvent, useCallback } from 'react';
import trackEvent from '@/client/helpers/trackEvent';
import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';
import useQueryParam from '@/client/hooks/router/useQueryParam';

const useSearchInput = () => {
  const [search, setSearch] = useQueryParam('search');

  const handleChange = useCallback(
    (targetValue: string) => {
      setSearch(targetValue);

      sendEventToUmami(
        {
          term: targetValue,
        },
        'executeSearch',
      );
    },
    [setSearch],
  );
  const handleBlur = useCallback(
    () => trackEvent({ searchTerm: search }),
    [search],
  );
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value);
  };
  return { search, handleChange, handleBlur, handleInputChange };
};

export default useSearchInput;
