import Link from '@/client/components/Link';
import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';
import getCdnImg from '@/shared/cdn/getCdnImg';
import Img from '@/shared/components/Img';

type Props = { handle?: string; name?: string };

const ProviderResult: FC<Props> = ({ name = '', handle = '' }) => {
  const sendEvent = () => {
    sendEventToUmami(
      {
        type: 'provider',
        handle,
      },
      'selectSearchResult',
    );
  };

  return (
    <Link
      href={`/providers/${handle}`}
      data-gtm-event-action="search_results"
      data-gtm-event-category="provider_card_link"
      data-gtm-event-info={handle}
      onClick={sendEvent}
    >
      <div className="grid grid-flow-col grid-cols-[max-content_1fr] items-center gap-4">
        <div className="flex items-center justify-center rounded-moon-s-sm bg-goten p-2 rem:min-h-[48px] rem:min-w-[110px]">
          <Img
            src={getCdnImg(`providers/${handle}.svg`)}
            alt={name}
            width={40}
            height={40}
            className="h-full max-h-10"
          />
        </div>
        <span className="text-moon-10-caption font-medium uppercase text-bulma">
          {name}
        </span>
      </div>
    </Link>
  );
};

export default ProviderResult;
