import CategoryResult from './CategoryResult';
import ResultHeader from '@/client/components/NavBarSearch/SearchView/ResultSection/shared/ResultHeader';

type Props = {
  search?: string;
  data?: MenuItemConnectionFragment;
};

const CategoryResults: FC<Props> = ({ data, search = '' }) => {
  if (!data?.edges?.length) {
    return null;
  }
  return (
    <div className="grid">
      <ResultHeader
        search={search}
        section="casino.categories"
        totalCount={data.edges.length}
      />
      <div className="grid">
        {data.edges.map(({ node }) => (
          <div className="grid gap-4 py-1" key={node?.handle}>
            <CategoryResult name={node?.name} handle={node?.handle} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryResults;
