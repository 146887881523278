const updateQueryParam = (name: string, value?: string) => {
  const search = global?.location?.search ?? '';
  const params = new URLSearchParams(search);
  if (value) {
    params.set(name, value);
  } else {
    params.delete(name);
  }
  const newPathQuery = buildPathQuery(params);
  global?.history.replaceState(global.history.state, '', newPathQuery);
};

const buildPathQuery = (params: URLSearchParams) => {
  const { hash, pathname } = global?.location ?? {};
  const parsedParams = params.toString();
  if (parsedParams) {
    return `${pathname}?${parsedParams}${hash}`;
  }
  return `${pathname}${hash}`;
};

export default updateQueryParam;
