import useNavBarSearchContext from '../../context/useNavBarSearchContext';
import Tabs from '@/external/components/Tabs';

type Props = {
  tabs: string[];
  onClose: () => void;
  onChange: (index: number) => void;
};

const SearchTabs: FC<Props> = ({ tabs, onClose, onChange }) => {
  const { translations } = useNavBarSearchContext();
  return (
    <Tabs
      onChange={onChange}
      onClose={onClose}
      tabs={tabs.map((tab) => translations[`casino.search.${tab}`])}
    />
  );
};

export default SearchTabs;
