import { createContext } from 'react';

const NavBarSearchContext = createContext<NavBarSearchContextProps>({
  translations: {},
  linkProps: {
    domain: '',
    language: 'en',
    defaultLang: 'en',
    isBridgeSite: false,
  },
  site: 'bitcasino',
});

export default NavBarSearchContext;
