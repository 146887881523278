import useNavBarSearchContext from '@/client/components/NavBarSearch/context/useNavBarSearchContext';
import GameResult from '@/client/components/NavBarSearch/SearchView/ResultSection/GameResults/GameResult';
import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';
import saveEventData from '@/client/helpers/trackEvent/umamiEvents/saveEventData';

type Props = {
  data?: PopularCasinoSearchFragment[];
  search: string;
  isShowOnFirstChars?: boolean;
};

const PopularResults: FC<Props> = ({
  data,
  search,
  isShowOnFirstChars = true,
}) => {
  const { translations } = useNavBarSearchContext();
  if (!data?.length || (search.length > 2 && isShowOnFirstChars)) {
    return null;
  }
  return (
    <div className="grid gap-2">
      <h3 className="text-moon-16 font-medium text-bulma">
        {translations['casino.popular_searches']}
      </h3>
      <div className="grid gap-2">
        {data.map(({ node }, index) => {
          const game = node?.game;
          if (!game) {
            return null;
          }

          const sendEvent = () => {
            saveEventData({
              event: 'launchGame',
              page: 'search',
              slider: {
                name: 'search_popular',
                sliderTileIndex: index + 1,
              },
            });

            sendEventToUmami(
              {
                type: 'game',
                handle: game.handle,
              },
              'selectSearchResult',
            );
          };

          return (
            <div
              className="grid gap-1"
              key={game.handle}
              data-gtm-event-action="search_results"
              data-gtm-event-category="popular_card_link"
              data-gtm-event-info={game.handle}
              onClick={sendEvent}
            >
              <GameResult game={game} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PopularResults;
