'use client';
import useSearchView from './hooks/useSearchView';
import ResultSection from './ResultSection';
import SearchTabs from './SearchTabs';

type Props = {
  isOpen?: boolean;
  search?: string;
  onClose: () => void;
};

const SearchView: FC<Props> = ({ isOpen, search, onClose }) => {
  const {
    loading,
    categoryResults,
    providerResults,
    contentResults,
    gameResults,
    showSeeAllResults,
    popular,
    isEmptyResults,
    tabNames,
    handleTabChange,
    debouncedSearch,
  } = useSearchView({ isOpen, search });

  return (
    <div className="grid max-h-[calc(100vh-84px)] w-full gap-4 overflow-hidden overflow-y-scroll">
      <div className="grid gap-2 p-2">
        <SearchTabs
          tabs={tabNames}
          onClose={onClose}
          onChange={handleTabChange}
        />
        <hr className="-mt-2 h-px border-0 bg-beerus" />
        <ResultSection
          search={debouncedSearch}
          loading={loading}
          categoryResults={categoryResults}
          providerResults={providerResults}
          contentResults={contentResults}
          gameResults={gameResults}
          popular={popular}
          showSeeAllResults={showSeeAllResults}
          isEmptyResults={isEmptyResults}
        />
      </div>
    </div>
  );
};

export default SearchView;
