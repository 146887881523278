'use client';
import { useState } from 'react';
import useSearchInput from './SearchView/hooks/useSearchInput';

const useNavBarSearch = () => {
  const { search, handleChange, handleBlur } = useSearchInput();
  const [isOpen, handleOpen] = useState(Boolean(search));
  const handleClose = (event?: Event) => {
    event?.stopPropagation();
    handleOpen(false);
  }
  return {
    search,
    isOpen,
    handleOpen,
    handleClose,
    handleChange,
    handleBlur,
  };
};

export default useNavBarSearch;
