const getTranslationKey = (type: string) => `game.label_${type}`;

const getBadge = (game?: GameFragment, site?: Site) => {
  if (game?.isTrending) {
    return {
      translationKey: getTranslationKey('trending'),
      color: 'bg-chichi',
    };
  }
  if (game?.isHot) {
    return {
      translationKey: getTranslationKey('hot'),
      color: 'bg-popo',
    };
  }
  if (game?.isNew) {
    return {
      translationKey: getTranslationKey('new'),
      color: site === 'empire' ? 'bg-cell' : 'bg-roshi',
    };
  }
  if (game?.isHighRoller) {
    return {
      translationKey: getTranslationKey('high-roller'),
      color: 'bg-popo',
    };
  }
  return null;
};

export default getBadge;
