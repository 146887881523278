'use client';
import GameResult from './GameResult';
import ResultHeader from '@/client/components/NavBarSearch/SearchView/ResultSection/shared/ResultHeader';
import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';
import saveEventData from '@/client/helpers/trackEvent/umamiEvents/saveEventData';

type Props = {
  search?: string;
  data?: GameConnectionFragment;
};

const GameResults: FC<Props> = ({ data, search = '' }) => {
  if (!data?.edges?.length) {
    return null;
  }

  return (
    <div className="grid gap-2">
      <ResultHeader
        search={search}
        section="casino.search.games"
        totalCount={data.totalCount ?? 0}
      />
      <div className="grid gap-2">
        {data.edges.map(({ node }, index) => {
          if (!node) {
            return null;
          }

          const sendEvent = () => {
            saveEventData({
              event: 'launchGame',
              page: 'search',
              slider: {
                name: 'search',
                sliderTileIndex: index + 1,
              },
            });

            sendEventToUmami(
              {
                type: 'game',
                handle: node.handle,
              },
              'selectSearchResult',
            );
          };

          return (
            <div
              className="grid gap-1"
              key={node.handle}
              data-gtm-event-action="search_results"
              data-gtm-event-category="game_card_link"
              data-gtm-event-info={node.handle}
              onClick={sendEvent}
            >
              <GameResult game={node} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GameResults;
