import CategoryResults from './CategoryResults';
import ContentResults from './ContentResults';
import GameResults from './GameResults';
import PopularResults from './PopularResults';
import ProviderResults from './ProviderResults';
import SeeAllResults from './shared/SeeAllResults';
import ZeroResultsCaption from './ZeroResultsCaption';
import useNavBarSearchContext from '@/client/components/NavBarSearch/context/useNavBarSearchContext';

type Props = {
  loading?: boolean;
  showSeeAllResults?: boolean;
  search?: string;
  popular?: PopularCasinoSearchFragment[];
  gameResults?: GameConnectionFragment;
  categoryResults?: MenuItemConnectionFragment;
  providerResults?: MenuItemConnectionFragment;
  contentResults?: PageConnectionFragment;
  isEmptyResults?: boolean;
};

const ResultSection: FC<Props> = ({
  loading,
  popular,
  categoryResults,
  providerResults,
  contentResults,
  gameResults,
  showSeeAllResults,
  search = '',
  isEmptyResults,
}) => {
  const { translations } = useNavBarSearchContext();
  if (loading) {
    return (
      <span className="text-moon-10-caption font-medium uppercase text-trunks">
        {translations['general.searching']}
      </span>
    );
  }

  return (
    <div className="grid gap-4">
      <ZeroResultsCaption isEmptyResults={isEmptyResults} search={search} />
      <PopularResults
        search={search}
        data={popular}
        isShowOnFirstChars={!isEmptyResults}
      />
      <GameResults search={search} data={gameResults} />
      <CategoryResults search={search} data={categoryResults} />
      <ProviderResults search={search} data={providerResults} />
      <ContentResults search={search} data={contentResults} />
      {showSeeAllResults && <SeeAllResults search={search} />}
    </div>
  );
};

export default ResultSection;
