import classNames from '@/external/classNames';
import Tag from '@/external/components/Tag';

type Props = {
  badge: Record<string, string> | null;
  className?: string;
};

const GameBadge: FC<Props> = ({ children, badge, className }) => (
  <div className={classNames('absolute top-1 left-1 z-2', className)}>
    <Tag
      color="text-goten"
      bgColor={badge?.color}
      size="2xs"
      className="px-1 sm:px-2"
    >
      {children}
    </Tag>
  </div>
);

export default GameBadge;
