enum DeviceType {
  DESKTOP,
  ANDROID_PHONE,
  ANDROID_TABLET,
  IOS_PHONE,
  IOS_TABLET,
  WINDOWS_PHONE,
  WINDOWS_TABLET,
}

export default DeviceType;
