import useNavBarSearchContext from '@/client/components/NavBarSearch/context/useNavBarSearchContext';
import replaceVariables from '@/shared/utils/translations/replaceVariables';

type Props = {
  search?: string;
  isEmptyResults?: boolean;
};

const ZeroResultsCaption: FC<Props> = ({ search = '', isEmptyResults }) => {
  const { translations } = useNavBarSearchContext();
  const translation = replaceVariables(
    translations['general.search_results_zero'],
    { search },
  );
  if (!isEmptyResults || search.length < 3) {
    return null;
  }
  return (
    <span className="text-moon-10-caption uppercase text-trunks">
      {translation}
    </span>
  );
};

export default ZeroResultsCaption;
