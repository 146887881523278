'use client';
import DeviceType from '@/shared/utils/DeviceType';
import isSsr from '@/shared/utils/isSsr';

const getDeviceType = (): DeviceType => {
  if (isSsr()) {
    return DeviceType.DESKTOP; // Desktop by default
  }

  const ua = navigator.userAgent;

  if (ua.includes('Tablet')) {
    return DeviceType.ANDROID_TABLET;
  }
  if (ua.includes('iPad')) {
    return DeviceType.IOS_TABLET;
  }
  if (ua.includes('Android')) {
    return DeviceType.ANDROID_PHONE;
  }
  if (/iPhone|iPod/.test(ua)) {
    return DeviceType.IOS_PHONE;
  }
  return DeviceType.DESKTOP;
};

export default getDeviceType;
