'use client';
import { Tabs as MoonTabs } from '@heathmont/moon-core-tw';
import ControlsCloseSmall from '@/external/icons/ControlsCloseSmall';

type Props = {
  tabs: string[];
  onClose: () => void;
  onChange: (index: number) => void;
};

const Tabs: FC<Props> = ({ tabs, onClose, onChange }) => (
  <MoonTabs onChange={onChange}>
    <div className="grid grid-flow-col grid-cols-minmax items-baseline gap-4">
      <MoonTabs.List>
        {tabs.map((tab) => (
          <MoonTabs.Tab key={tab} className="w-auto" as="a">
            {tab}
          </MoonTabs.Tab>
        ))}
      </MoonTabs.List>
      <div className="cursor-pointer w-6 h-6" onClick={onClose}>
        <ControlsCloseSmall />
      </div>
    </div>
  </MoonTabs>
);

export default Tabs;
