import Img from '@/shared/components/Img';

type Props = { game: GameFragment };

const GameImg: FC<Props> = ({ game }) => (
  <div className="group relative overflow-hidden rounded-moon-s-sm">
    <Img
      fit="crop"
      width={88}
      height={66}
      alt={game.name ?? ''}
      src={game.thumbUrl ?? ''}
    />
  </div>
);

export default GameImg;
