import { create } from 'zustand';

type Props = {
  search: string;
  setSeacrh: (search: string) => void;
};

const useSearchStore = create<Props>((set) => ({
  search: '',
  setSeacrh: (search: string) => set({ search }),
}));

export default useSearchStore;
