'use client';
import { useState, useEffect } from 'react';
import getPopularSearches from '@/actions/search/getPopularSearches';

type Args = {
  isOpen?: boolean;
  search?: string;
  deviceType?: number;
  isEmptyResults?: boolean;
};

const usePopularSearchData = ({
  isOpen,
  search = '',
  deviceType = 0,
  isEmptyResults,
}: Args) => {
  const [popular, setPopular] = useState<PopularCasinoSearchFragment[]>();
  const [loadingPopular, setLoadingPopular] = useState(true);

  useEffect(() => {
    const getPopularSearchData = async () => {
      const { popular: popularData } = await getPopularSearches();
      setPopular(popularData);
      setLoadingPopular(false);
    };
    const isSkip = !isOpen || (search.length > 2 && !isEmptyResults);
    if (!isSkip) {
      getPopularSearchData();
    } else {
      setPopular([]);
      setLoadingPopular(false);
    }
  }, [isOpen, search, isEmptyResults, deviceType]);

  return { loadingPopular, popular };
};

export default usePopularSearchData;
