import GameBadge from './Badge';
import Link from '@/client/components/Link';
import useNavBarSearchContext from '@/client/components/NavBarSearch/context/useNavBarSearchContext';
import GameImg from '@/client/components/NavBarSearch/SearchView/ResultSection/shared/GameImg';

type Props = { game: GameFragment };

const GameResult: FC<Props> = ({ game }) => {
  const { translations } = useNavBarSearchContext();
  return (
    <Link href={game.href ?? ''}>
      <div className="relative grid grid-cols-[max-content_1fr] items-center gap-4">
        <GameImg game={game} />
        <div className="flex h-full flex-col items-start">
          <span className="text-moon-14 font-medium text-bulma">
            {game.name}
          </span>
          <span className="grow text-moon-10-caption font-medium uppercase text-trunks">
            {game.menuItem?.[0]?.name}
          </span>
          <GameBadge game={game} translations={translations} />
        </div>
      </div>
    </Link>
  );
};

export default GameResult;
