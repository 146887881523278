import { useEffect, useState } from 'react';
import getSearchQuery from '@/actions/search/getSearchQuery';
import useDebounce from '@/client/hooks/useDebounce';

type Args = {
  isOpen?: boolean;
  search?: string;
  deviceType?: number;
  active?: string;
  first?: number;
};

type SearchQueryData = Awaited<ReturnType<typeof getSearchQuery>>['data'];

const useSearchData = ({ search = '', active = 'all', first = 5 }: Args) => {
  const [data, setData] = useState<SearchQueryData>();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const debouncedSearch = useDebounce(search, 1000);

  useEffect(() => {
    if (debouncedSearch.length < 3) {
      setData({});
      setLoadingSearch(false);
      return;
    }
    const getSearchData = async () => {
      const { data: searchData } = await getSearchQuery({
        search: debouncedSearch,
        section: active,
        first,
      });
      setData(searchData);
      setLoadingSearch(false);
    };
    setData(undefined);
    setLoadingSearch(true);
    getSearchData();
  }, [active, first, debouncedSearch]);

  return {
    data,
    loadingSearch,
    debouncedSearch,
  };
};

export default useSearchData;
