import useNavBarSearchContext from '@/client/components/NavBarSearch/context/useNavBarSearchContext';
import GameBadge from '@/shared/components/GameBadge';
import getBadge from '@/shared/components/GameBadge/helpers/getBadge';

type Props = {
  game: GameFragment;
  translations?: Record<string, string>;
};

const Badge: FC<Props> = ({ game, translations }) => {
  const { site } = useNavBarSearchContext();
  const badge = getBadge(game, site);
  if (!badge) {
    return null;
  }

  return (
    <GameBadge badge={badge}>
      {translations?.[badge.translationKey] ?? badge.translationKey}
    </GameBadge>
  );
};

export default Badge;
