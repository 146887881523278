import useNavBarSearchContext from '@/client/components/NavBarSearch/context/useNavBarSearchContext';
import replaceVariables from '@/shared/utils/translations/replaceVariables';

type Props = {
  totalCount?: number;
  search?: string;
};

const TotalCount: FC<Props> = ({ totalCount = 0, search = '' }) => {
  const { translations } = useNavBarSearchContext();
  const oneResultTranslation = replaceVariables(
    translations['general.search_results_one'],
    { search },
  );
  if (totalCount === 1) {
    return <span>{oneResultTranslation}</span>;
  }
  const manyResultTranslation = replaceVariables(
    translations['general.search_results_many'],
    { totalCount, search },
  );
  return <span>{manyResultTranslation}</span>;
};

export default TotalCount;
