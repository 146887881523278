'use client';

import { usePathname, useRouter } from 'next/navigation';
import Button from '@/external/components/Button';

type Props = {
  text: string;
};

const MyBetsLink: FC<Props> = ({ text }) => {
  const pathname = usePathname();
  const router = useRouter();

  const isMainPage = pathname === '/casino';

  if (!isMainPage) {
    return null;
  }

  return (
    <Button
      onClick={() => {
        router.push('/user/my-bets/active');
      }}
      className="bg-hit text-bulma"
    >
      {text}
    </Button>
  );
};

export default MyBetsLink;
