import ProviderResult from './ProviderResult';
import ResultHeader from '@/client/components/NavBarSearch/SearchView/ResultSection/shared/ResultHeader';

type Props = {
  search?: string;
  data?: MenuItemConnectionFragment;
};

const ProviderResults: FC<Props> = ({ data, search = '' }) => {
  if (!data?.edges?.length) {
    return null;
  }

  return (
    <div className="grid gap-2">
      <ResultHeader
        search={search}
        section="casino.providers"
        totalCount={data.edges.length}
      />
      <div className="grid gap-2">
        {data.edges.map(({ node }) => (
          <div className="grid gap-1" key={node?.handle}>
            <ProviderResult handle={node?.handle} name={node?.name} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProviderResults;
