import TotalCount from './TotalCount';
import useNavBarSearchContext from '@/client/components/NavBarSearch/context/useNavBarSearchContext';

type Props = {
  section: string;
  totalCount?: number;
  search?: string;
};

const ResultHeader: FC<Props> = ({ section, totalCount = 0, search = '' }) => {
  const { translations } = useNavBarSearchContext();
  return (
    <span className="text-moon-10-caption font-medium uppercase text-trunks">
      {translations[section]}{' '}
      <TotalCount totalCount={totalCount} search={search} />
    </span>
  );
};

export default ResultHeader;
