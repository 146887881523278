'use client';
import { usePathname } from 'next/navigation';
import NavBarSearchContext from './context/NavBarSearchContext';
import SearchView from './SearchView';
import useNavBarSearch from './useNavBarSearch';
import classNames from '@/external/classNames';
import Search from '@/external/components/Search';

const NavBarSearch: FC<NavBarSearchContextProps> = (props) => {
  const { search, isOpen, handleChange, handleOpen, handleClose } =
    useNavBarSearch();
  const pathname = usePathname();
  const isSearchPage = pathname === '/search' || pathname === '/casino/search';
  return (
    <NavBarSearchContext.Provider value={props}>
      <div className="hidden rem:w-[400px] z-[100] md:block rem:lg:w-[552px]">
        <Search
          search={search}
          onChangeSearch={handleChange}
          onChangeOpen={handleOpen}
          isOpen={isOpen}
        >
          <Search.Input>
            <Search.Input.Icon className="text-trunks" />
            <Search.Input.Input
              placeholder={props.translations['casino.search_tip']}
              className="placeholder-trunks"
            />
          </Search.Input>
          <Search.Transition>
            <Search.Result
              className={classNames('pb-0', isSearchPage && 'hidden')}
            >
              <SearchView
                isOpen={isOpen}
                search={search}
                onClose={handleClose}
              />
            </Search.Result>
          </Search.Transition>
        </Search>
      </div>
    </NavBarSearchContext.Provider>
  );
};

export default NavBarSearch;
