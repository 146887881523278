import Link from '@/client/components/Link';
import useNavBarSearchContext from '@/client/components/NavBarSearch/context/useNavBarSearchContext';
import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';

type Props = { search: string };

const SeeAllResults: FC<Props> = ({ search }) => {
  const { translations } = useNavBarSearchContext();
  const href = encodeURI(`/search?search=${search}`);

  const sendEvent = () => {
    sendEventToUmami({}, 'seeAllResults');
  };

  return (
    <div className="grid gap-4">
      <hr className="h-px border-0 bg-beerus" />
      <Link href={href} onClick={sendEvent}>
        <p className="text-moon-14 font-semibold">
          {translations['general.see_all_results']}
        </p>
      </Link>
    </div>
  );
};

export default SeeAllResults;
