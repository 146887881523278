import Link from '@/client/components/Link';
import useNavBarSearchContext from '@/client/components/NavBarSearch/context/useNavBarSearchContext';
import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';
import replaceVariables from '@/shared/utils/translations/replaceVariables';

type Props = { name?: string; handle?: string };

const CategoryResult: FC<Props> = ({ name = '', handle = '' }) => {
  const { translations } = useNavBarSearchContext();
  const translation = replaceVariables(translations['casino.category_name'], {
    name,
  });

  const sendEvent = () => {
    sendEventToUmami(
      {
        type: 'category',
        handle,
      },
      'selectSearchResult',
    );
  };

  return (
    <Link href={`/categories/${handle}`} onClick={sendEvent}>
      <span
        data-gtm-event-category="category_card_link"
        data-gtm-event-action="search_results"
        data-gtm-event-info={handle}
      >
        <span className="block text-moon-14 font-semibold text-bulma">
          {translation}
        </span>
      </span>
    </Link>
  );
};

export default CategoryResult;
