import getIsZeroResults from './getIsZeroResults';

const getAllResults = (data?: AllSearchResultsQuery) => {
  const {
    SearchResultsCategoriesConnection: categoryResults,
    SearchResultsGamesConnection: gameResults,
    SearchResultsProvidersConnection: providerResults,
  } = data?.casino || {};
  const contentResults = data?.content?.SearchResultsContentConnection;
  const isEmptyResults = getIsZeroResults(
    categoryResults,
    providerResults,
    gameResults,
    contentResults,
  );

  return {
    categoryResults,
    providerResults,
    gameResults,
    contentResults,
    isEmptyResults,
  };
};

export default getAllResults;
