'use client';
import { useState } from 'react';
import getAllResults from './helpers/getAllResults';
import getShouldShowAll from './helpers/getShouldShowAll';
import usePopularSearchData from '@/client/components/NavBarSearch/SearchView/hooks/usePopularSearchData';
import useSearchData from '@/client/components/NavBarSearch/SearchView/hooks/useSearchData';
import getDeviceType from '@/client/helpers/getDeviceType';
import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';

const tabNames = ['all', 'games', 'live-casino', 'other'];

type Args = {
  isOpen?: boolean;
  first?: number;
  search?: string;
};

const useSearchView = ({ isOpen, first, search = '' }: Args) => {
  const [active, setIsActive] = useState('all');
  const deviceType = getDeviceType();
  const { data, loadingSearch, debouncedSearch } = useSearchData({
    search,
    active,
    first,
    deviceType,
  });
  const {
    categoryResults,
    providerResults,
    gameResults,
    contentResults,
    isEmptyResults,
  } = getAllResults(data);
  const encodedSearch = encodeURI(search);
  const { popular, loadingPopular } = usePopularSearchData({
    isOpen,
    search,
    deviceType,
    isEmptyResults,
  });

  const handleTabChange = (index: number) => {
    const selectedTab = tabNames[index];

    sendEventToUmami(
      {
        eventCategory: 'button_link',
        eventAction: 'search_toolbar',
        eventInfo: `search_section_${selectedTab}`,
      },
      'elementInteraction',
    );

    sendEventToUmami(
      {
        tab: selectedTab,
      },
      'changeSearchTab',
    );

    setIsActive(selectedTab);
  };

  return {
    active,
    setIsActive,
    tabNames,
    handleTabChange,
    loading: loadingSearch || loadingPopular,
    categoryResults,
    providerResults,
    gameResults,
    contentResults,
    popular,
    showSeeAllResults: getShouldShowAll({
      providerResults,
      categoryResults,
      gameResults,
      contentResults,
    }),
    search: decodeURI(encodedSearch),
    isEmptyResults,
    debouncedSearch,
  };
};

export default useSearchView;
