const getIsZeroResults = (
  categoryResults?: MenuItemConnectionFragment,
  providerResults?: MenuItemConnectionFragment,
  gameResults?: GameConnectionFragment,
  contentResults?: PageConnectionFragment,
) =>
  isEmpty(categoryResults?.edges) &&
  isEmpty(providerResults?.edges) &&
  isEmpty(gameResults?.edges) &&
  isEmpty(contentResults?.edges);

const isEmpty = (list: unknown[] = []) => list.length === 0;

export default getIsZeroResults;
