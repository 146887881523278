import ContentResult from './ContentResult';
import ResultHeader from '@/client/components/NavBarSearch/SearchView/ResultSection/shared/ResultHeader';

type Props = {
  search?: string;
  data?: PageConnectionFragment;
};

const ContentResults: FC<Props> = ({ data, search = '' }) => {
  if (!data?.edges?.length) {
    return null;
  }

  return (
    <div className="grid gap-1">
      <ResultHeader
        search={search}
        section="casino.pages"
        totalCount={data.totalCount}
      />
      <div className="grid gap-2">
        {data.edges.map(({ node }) => (
          <div className="grid gap-1" key={node?.handle}>
            <ContentResult
              handle={node?.handle}
              name={node?.name}
              section={node?.sectionHandle}
              mainBlock={node?.mainBlock}
              search={search}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContentResults;
