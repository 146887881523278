import getTextContent from './getTextContent';
import Link from '@/client/components/Link';
import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';
import classNames from '@/external/classNames';

type Props = {
  name?: string;
  handle?: string;
  search?: string;
  mainBlock?: string;
  section?: string;
};

const ContentResult: FC<Props> = ({
  name = '',
  handle = '',
  mainBlock = '',
  section = '',
  search = '',
}) => {
  const href = getResultHref({ section, handle });

  const sendEvent = () => {
    sendEventToUmami(
      {
        type: 'article',
        handle,
      },
      'selectSearchResult',
    );
  };

  return (
    <Link href={href} onClick={sendEvent}>
      <span
        data-gtm-event-action="search_results"
        data-gtm-event-category="text_link"
        data-gtm-event-info={`${section}_${handle}`}
      >
        <h2 className="text-moon-24 font-semibold text-bulma">{name}</h2>
        <p
          className={classNames(
            'text-moon-14 text-trunks line-clamp-2',
            '[&_*]:!text-moon-14 [&_*]:!font-normal [&_*]:!not-italic [&_*]:!text-trunks',
            '[&_h1]:!hidden [&_h2]:!hidden [&_h3]:!hidden [&_h5]:!hidden [&_h6]:!hidden [&_img]:!hidden',
          )}
          dangerouslySetInnerHTML={{
            __html: getTextContent(mainBlock, search),
          }}
        />
      </span>
    </Link>
  );
};

const getResultHref = ({ section, handle }: Props) => {
  if (section?.includes('help') || section?.includes('how')) {
    return `/help-center/${section}/${handle}`;
  }
  if (section?.includes('promotions')) {
    return `/promotions/${handle}`;
  }
  return `/${section?.replace('-', '/')}/${handle}`;
};

export default ContentResult;
