type Args = {
  providerResults?: MenuItemConnectionFragment;
  categoryResults?: MenuItemConnectionFragment;
  gameResults?: GameConnectionFragment;
  contentResults?: PageConnectionFragment;
};

const getShouldShowAll = ({
  categoryResults,
  providerResults,
  gameResults,
  contentResults,
}: Args) =>
  (categoryResults?.edges?.length ?? 0) > 5 ||
  (gameResults?.totalCount ?? 0) > 5 ||
  (providerResults?.edges?.length ?? 0) > 5 ||
  (contentResults?.totalCount ?? 0) > 5;

export default getShouldShowAll;
