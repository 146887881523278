const replaceVariables = (
  translation: string,
  values: TranslateOptions['values'],
) => {
  if (!translation.includes('{')) {
    return translation;
  }
  const entries = Object.entries(values ?? []);
  return entries.reduce((accumulated, [key, value]) => {
    const regex = new RegExp(`{\\s*${key}\\s*}`, 'g');
    return accumulated.replace(regex, value?.toString() ?? '');
  }, translation);
};

export default replaceVariables;
